// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-article-article-tsx": () => import("./../../../src/templates/article/article.tsx" /* webpackChunkName: "component---src-templates-article-article-tsx" */),
  "component---src-templates-page-page-tsx": () => import("./../../../src/templates/page/page.tsx" /* webpackChunkName: "component---src-templates-page-page-tsx" */),
  "component---src-templates-story-story-tsx": () => import("./../../../src/templates/story/story.tsx" /* webpackChunkName: "component---src-templates-story-story-tsx" */),
  "component---src-templates-tree-tree-tsx": () => import("./../../../src/templates/tree/tree.tsx" /* webpackChunkName: "component---src-templates-tree-tree-tsx" */)
}

