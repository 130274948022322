import React, { createContext, useEffect, useState } from 'react'
import parse from 'html-react-parser'
import Page from '../pages/index.json'
// import { graphql, useStaticQuery } from 'gatsby'

const Context = createContext<any>(null)

const ContextProvider = ({ children }) => {
  const [scroll, setScroll] = useState(0);
  const [scrolled, setScrolled] = useState(false);
  const [search, setSearch] = useState('');
  const [location, setLocation] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const [filters, setFilters] = useState({})
  const [checkout, setCheckout] = useState(null);
  const [language, setLanguage] = useState('en');
  const [languagesOpen, setLanguagesOpen] = useState(false);
  const [donateOpen, setDonateOpen] = useState(false);
  const [mouseWheel, setMouseWheel] = useState(0);
  const [heroHeight, setHeroHeight] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [donateUrl, setDonateUrl] = useState('');
  const [treeImages, setTreeImages] = useState({})

  const getSeason = () => {
    const today = new Date();
    const month = today.getMonth();
    if (month >= 9 || month <= 2) {
      return 'fall'
    } else return 'summer'
  }

  useEffect(() => {
    fetch('/.netlify/functions/getTreeImages', {
      method: 'POST',
    })
    .then(res => res.json())
    .then(response => {
      if (response.data && response.data.length) {
        const imageMap = {}
        response.data.forEach(t => {
          imageMap[t.id] = t?.fields?.Attachments ?? []
        })
        setTreeImages(imageMap)
      }
    })
  }, [])

  const [season, setSeason] = useState(getSeason());
  
  return (
    <Context.Provider value={{ scroll, setScroll, scrolled, setScrolled, search, setSearch, location, 
      menuOpen, setMenuOpen, filters, setFilters, checkout, setCheckout,language, setLanguage,
      languagesOpen, setLanguagesOpen, mouseWheel, setMouseWheel, heroHeight, setHeroHeight, season, donateOpen, setDonateOpen, donateUrl, setDonateUrl, treeImages }}>
      {children}
    </Context.Provider>
  )
}


export { Context, ContextProvider }